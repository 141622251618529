// libs
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'

// app
import { client } from '../../api/client'
import { URL, DOC, RECEIVING, cell } from "../../helpers/coda-api"
import { slackURL, CHANNEL } from '../../helpers/slack-api'
import 'regenerator-runtime'



// Slack
const _postToSlack = createAsyncThunk(
  'receiving/postToSlack',
  async ({ content, channelName }, { rejectWithValue }) => {
    const channelId = CHANNEL[channelName] === undefined ? CHANNEL.test : CHANNEL[channelName]
    const res = await client.post(
      slackURL + "/chat.postMessage/" + channelId,
      content
    )
    return res
  }
)


const _fetchReceiving = createAsyncThunk(
  'receiving/fetch',
  async () => {
    const res = await client.get(URL + "/" + DOC.TTS.id + "/tables/" + DOC.TTS.tables.RECEIVING + "/rows")
    return res
  }
)

const _putReceivingUpdate = createAsyncThunk(
  'receiving/update',
  // first param is the object sent to the _putReceivingUpdate() method
  async ({ rowId, cells }, { rejectWithValue }) => {
    try {
      const res = await client.put(
        URL + "/" + DOC.TTS.id + "/tables/" + DOC.TTS.tables.RECEIVING + "/rows/" + rowId,
        {
          row: { cells: cells }
        }
      )
      return res
    } catch (err) {
      //https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
      return rejectWithValue(err)
    }
  }
)



const _fetchReceivingPartial = createAsyncThunk(
  'receiving/fetchPartial',
  async () => {
    console.log("fetching partial")
    const res = await client.get(URL + "/" + DOC.TTS.id + "/tables/" + DOC.TTS.tables.RECEIVING_PARTIAL + "/rows")
    return res
  }
)

// logs when an item is received, but there is a problem
const _postReceivingPartial = createAsyncThunk(
  'receiving/partialAdd',
  // first param is the object sent to the _postReceivingPartial() method
  // NOTE: the API does provide adding multiple rows at once, but we are going to hardcode to one row at a time, so we only need the cells
  async ({ cells }, { rejectWithValue }) => {
    try {
      const res = await client.post(
        URL + "/" + DOC.TTS.id + "/tables/" + DOC.TTS.tables.RECEIVING_PARTIAL + "/rows",
        {
          rows: [{ cells: cells }]
        }
      )
      return res
    } catch (err) {
      //https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
      return rejectWithValue(err)
    }
  }
)


// ---
// DATA
// ---
const _normalizeData = payload => {
  return payload.items.reduce((prev, item, idx) => {
    prev.allIds.push(item.id)
    prev.byId[item.id] = item
    return prev
  }, { allIds: [], byId: {} })
}

const _initializeSession = payload => {
  return payload.items.reduce((prev, item, idx) => {
    prev.byId[item.id] = {
      // status of the submission (ie. local form errors) vs. api status (problem with request)
      // NOTE: opportunity to refactor and move this information into "local state" via reach useState hook
      status: '',
      errors: [],
      data: {},
      // @todo: better name for this?  how to track interaction with specific actions for an individual item (ie. update, etc)
      api: {
        status: '',
        error: ''
      }
    }
    return prev
  }, { byId: {} })
}


const _filterData = payload => {
  // NOTE: takes normalized data, so must first convert to an array for sorting and filtering
  const arr = payload.allIds.map(id => { return payload.byId[String(id)] })
  return arr.reduce((prev, item, idx) => {

    // [ 1 ] filter by searchTerm
    if (payload.searchTerm) {

      // search both job and vendor
      if (
        cell(item, RECEIVING.job) && cell(item, RECEIVING.job).match(new RegExp(payload.searchTerm, 'gi')) ||
        cell(item, RECEIVING.vendor) && cell(item, RECEIVING.vendor).match(new RegExp(payload.searchTerm, 'gi'))
      ) {

        if (cell(item, RECEIVING.recDate) && cell(item, RECEIVING.recDate).trim() !== "") prev.received.push(item)
          else prev.pending.push(item)
      }

    } else {
      // [ 2 ] breakout pending vs received
      // @todo: this is the exact same above in if statement - DRY
      if (cell(item, RECEIVING.recDate) && cell(item, RECEIVING.recDate).trim() !== "") prev.received.push(item)
      else prev.pending.push(item)
    }

    // [ 3 ] sort
    // PENDING --> sort by...
    if (payload.sort && payload.sort === 'job') {
      // ...job
      prev.pending.sort((a, b) => {
        return cell(a, RECEIVING.job) > cell(b, RECEIVING.job) ? 1 : -1
      })
      prev.received.sort((a, b) => {
        return cell(a, RECEIVING.job) > cell(b, RECEIVING.job) ? 1 : -1
      })

    } else if (payload.sort && payload.sort === 'date') {
      // ...date 
      prev.pending.sort((a, b) => {
        // EXPECTED date (not a required field)
        if (!cell(a, RECEIVING.expected)) return 1
        if (!cell(b, RECEIVING.expected)) return -1
        return cell(a, RECEIVING.expected) > cell(b, RECEIVING.expected) ? 1 : -1
      })

      prev.received.sort((a, b) => {
        // RECEIVED date (newest first)
        return cell(a, RECEIVING.recDate) > cell(b, RECEIVING.recDate) ? -1 : 1
      })
    } else {

      // ...vendor (default)
      prev.pending.sort((a, b) => {
        return cell(a, RECEIVING.vendor) > cell(b, RECEIVING.vendor) ? 1 : -1
      })
      prev.received.sort((a, b) => {
        return cell(a, RECEIVING.vendor) > cell(b, RECEIVING.vendor) ? 1 : -1
      })
    }
    return prev
  }, { sort: payload.sort || 'vendor', pending: [], received: [], searchTerm: payload.searchTerm || '' })
}

// ---
// STATE
// ---
const initialState = {
  // api is loaded data
  api: {
    status: '',
    allIds: [],
    byId: {}
  },
  // NOTE: we only need a 'slack' object here. It isnt critical to track the status of every slack API call per row in our data
  slack: {
    status: '',
    error: ''
  },
  // 'partial' represents the FETCH from receiving partial table, 'partialSave' is the POST of a new row
  partial: {
    status: '',
    error: '',
    allIds: []
  },
  partialSave: {
    status: '',
    error: ''
  },
  // session is in-memory data
  // @todo: a standard session object (errors, status, data, etc)
  session: { byId: {} },
  filtered: {
    sort: 'vendor',
    searchTerm: '',
    // NOTE: hardcoding these filtering options here is a tradeoff
    // -- these may need to be added to or replaced in the future
    // -- doing this, because we want React to automatically update display based on how we manipulate
    pending: [],
    received: []
  }
}


// ---
// SLICE
// ---
export const receivingSlice = createSlice({
  name: 'receiving',

  initialState,

  // reducers take current state and action and return a new state
  reducers: {

    filterReceiving(state, action) {
      state.filtered = _filterData({
        sort: action.payload.sort,
        searchTerm: action.payload.searchTerm,
        // redux toolkit function for getting state instead of Proxy
        allIds: current(state.api.allIds),
        byId: current(state.api.byId)
      })
    },

    updateSession(state, action) {
      state.session.byId[action.payload.id] = {
        ...state.session.byId[action.payload.id],
        status: action.payload.status,
        errors: action.payload.errors,
        data: action.payload.data
      }
    },

    resetSlackStatus(state, action) {
      state.slack = {
        ...state.slack,
        status: '',
        error: ''
      }
    }

  },

  // createAsyncThunk defines actions outside of createSlice() call,
  // extraReducers allows us to listen to other action types defined elsewhere
  extraReducers: builder => {
    builder
      // -- FETCH RECEIVING
      .addCase(_fetchReceiving.pending, (state, action) => { state.api.status = 'pending' })
      .addCase(_fetchReceiving.fulfilled, (state, action) => {

        // core item data
        const normalized = _normalizeData(action.payload)
        state.api = normalized

        // initialize session data
        state.session = _initializeSession(action.payload)

        state.filtered = _filterData(normalized)

        // add metadata
        state.api.status = 'fulfilled'
      })
      .addCase(_fetchReceiving.rejected, (state, action) => {
        state.api.status = 'rejected'
      })

      // -- PUT
      .addCase(_putReceivingUpdate.pending, (state, action) => { state.session.byId[action.meta.arg.rowId].api.status = 'pending' })
      .addCase(_putReceivingUpdate.fulfilled, (state, action) => { state.session.byId[action.meta.arg.rowId].api.status = 'fulfilled' })
      .addCase(_putReceivingUpdate.rejected, (state, action) => {
        state.session.byId[action.meta.arg.rowId].api.status = 'rejected'
        // using a string, can eventually use an object?
        state.session.byId[action.meta.arg.rowId].api.error =
          action.payload ? action.payload instanceof Error ? action.payload :
            action.payload.error ? action.payload.error :
              action.error ? action.error : "Unable to receive delivery. Unknown error." : "Error. No payload"
      })

      // -- SLACK
      .addCase(_postToSlack.pending, (state, action) => { state.slack.status = 'pending' })
      .addCase(_postToSlack.fulfilled, (state, action) => { state.slack.status = 'fulfilled' })
      .addCase(_postToSlack.rejected, (state, action) => {
        state.slack.status = 'rejected'
        state.slack.error = action.error ? action.error : 'Unable to post Slack message.'
      })

      // -- FETCH RECEIVING PARTIAL LOGs
      .addCase(_fetchReceivingPartial.pending, (state, action) => { state.partial.status = 'pending' })
      .addCase(_fetchReceivingPartial.fulfilled, (state, action) => {
        state.partial.status = 'fulfilled'
        state.partial.allIds = action.payload.items
      })
      .addCase(_fetchReceivingPartial.rejected, (state, action) => { state.partial.status = 'rejected' })

      // -- PARTIAL RECEIVING (problem with delivery)
      .addCase(_postReceivingPartial.pending, (state, action) => { state.partialSave.status = 'pending' })
      .addCase(_postReceivingPartial.fulfilled, (state, action) => { state.partialSave.status = 'fulfilled' })
      .addCase(_postReceivingPartial.rejected, (state, action) => {
        state.partialSave.status = 'rejected'
        state.partialSave.error = action.error ? action.error : 'Unable to save the problem with delivery.'
      })
  }

})

// Action creators are generated for each case reducer function
// "createSlice will automatically generate action creators" - https://redux.js.org/tutorials/fundamentals/part-8-modern-redux
export const { filterReceiving, updateSession, resetSlackStatus } = receivingSlice.actions
export const fetch = _fetchReceiving
export const update = _putReceivingUpdate
export const savePartial = _postReceivingPartial
export const fetchPartial = _fetchReceivingPartial
export const postToSlack = _postToSlack


export default receivingSlice.reducer
