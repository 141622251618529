// libs
import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Outlet, Link, useSearchParams, useNavigate } from "react-router-dom";
import { format, compareAsc, compareDesc, sub } from 'date-fns'

// app
import { fetch, filterReceiving } from './receivingSlice'
import { RECEIVING, cell } from "../../helpers/coda-api"
import { jobIconsRegex } from '../../helpers/utilities'
import _default from 'react-redux/es/components/connect'


export function ReceivingList() {

    let [queryParams, setQueryParams] = useSearchParams()

    // get data from store
    const _apiData = useSelector((state) => state.receiving.api)
    const _apiTotal = useSelector((state) => state.receiving.api.allIds).length

    const _receivingPending = useSelector((state) => state.receiving.filtered.pending)
    const _receivingReceived = useSelector((state) => state.receiving.filtered.received)

    const _filterSort = useSelector((state) => state.receiving.filtered.sort)
    const _filterSearchTerm = useSelector((state) => state.receiving.filtered.searchTerm)

    // how to dispatch actions
    const dispatch = useDispatch()

    // 2 days and 7 days old respectively
    const _lateShipDate = new Date(Date.now() - (2 * 24 * 60 * 60 * 1000))
    const _reallyLateShipDate = new Date(Date.now() - (8 * 24 * 60 * 60 * 1000))

    // ------
    // UI
    // ------
    // local state
    const [_uiShowOld, _uiUpdateShowOld] = useState(false)
    const [_uiActiveTab, _uiUpdateActiveTab] = useState('pending')
    const [_uiActiveReceiving, _uiUpdateActiveReceiving] = useState('xxx')
    // because Coda API calls are not immediate, we need to ensure someone doesnt try to re-receive something
    const [_uiReceivedReceiving, _uiUpdateReceivedReceiving] = useState('xxx')

    // ------
    // HANDLERS
    // ------
    const onShowOldChange = evt => {
        _uiUpdateShowOld(evt.target.checked)
    }

    const onSearchChange = (evt) => {
        evt.preventDefault()

        const searchTerm = document.getElementById("searchTerm").value ? document.getElementById("searchTerm").value.trim() : ''
        const doSearch = searchTerm.length > 2 || searchTerm.length === 0

        console.log("dispatch filterReceiving:", doSearch, searchTerm)
        if (doSearch) dispatch(filterReceiving({
            // when changing drop-down, update sort 
            // @todo: with removal of "searchField" (vendor vs job) is this still necessary?
            sort: _filterSort,
            searchTerm: searchTerm
        }))
    }

    const onVendorSort = (evt) => {
        evt.preventDefault()
        dispatch(filterReceiving({
            sort: "vendor",
            searchTerm: _filterSearchTerm || ''
        }))
    }

    const onDateSort = (evt) => {
        evt.preventDefault()
        dispatch(filterReceiving({
            sort: "date",
            searchTerm: _filterSearchTerm || ''
        }))
    }

    const onJobSort = (evt) => {
        evt.preventDefault()
        dispatch(filterReceiving({
            sort: "job",
            searchTerm: _filterSearchTerm || ''
        }))
    }

    const navigate = useNavigate()
    const onView = useCallback(({ evt, receivingId, showOld }) => {
        evt.preventDefault()
        navigate(`/receiving/${receivingId}?1=1`
            // need to disable scroll (do not pass) when showing old records (@todo: need to fix)
            + (!showOld ? '&scrollPos=' + window.scrollY : '')
            + (showOld ? '&showOld=true' : ''),
            { replace: true })
    }, [navigate])


    // ------
    // HOOKS
    // ------
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {

        // NOTE: if data has not been pulled (ie. !== fulfilled), then fetch
        _apiData.status !== 'fulfilled' && dispatch(fetch())

        // if passes the ?receivingIsReceived param to this route, reload our material
        // NOTE: we are not checking the value of `receivingIsReceived`
        if (queryParams && queryParams.get('receivingIsReceived')) {
            dispatch(fetch())
        }

        _uiUpdateActiveReceiving(queryParams && queryParams.get('hiliteReceiving') || 'xxx')
        _uiUpdateActiveTab(queryParams && queryParams.get('tab') || 'pending')
        _uiUpdateShowOld(queryParams && queryParams.get('old') === 'true' || false)
        _uiUpdateReceivedReceiving(queryParams && queryParams.get('receivedReceiving') || 'xxx')
        const _scrollY = (queryParams && queryParams.get('scrollY') && !isNaN(Number(queryParams.get('scrollY'))) ? Number(queryParams.get('scrollY')) : 0)

        // scroll is unstable, because it is doing scroll based on pending not received!
        let intervalId
        if (_scrollY > 0) intervalId = setInterval(() => { window.scrollTo(0, _scrollY); clearInterval(intervalId) }, 500)

        // clear all our query params
        setQueryParams({})

        // NOTE the [] as the second param, this ensures we only run useEffect() once
    }, []);

    // ------
    // RENDER
    // ------

    const _hiliteSearchText = text => {
        if (!_filterSearchTerm || _filterSearchTerm.length < 3) return text
        const _hilitSearchTerm = new RegExp(`(${_filterSearchTerm})`, 'gi')
        return text.replace(_hilitSearchTerm, '<mark class="hilit-searchtext">$1</mark>')
    }

    return (
        <div>

            <Outlet />

            {/* action bar */}
            {(_apiData.status === '' || _apiData.status == 'rejected') &&
                <button
                    className='btn btn-primary p-3'
                    aria-label="load"
                    onClick={() => dispatch(fetch())}
                >
                    <i className='bi-cloud-download'></i> Get Receiving Slips
                </button>
            }

            {/* CODA DATA | can we re-use this */}
            {_apiData.status === 'pending' && <div className="my-0 w-100 alert alert-warning" role="alert"><i className='bi-cloud-arrow-down'></i> Loading <strong>receiving slips</strong>...</div>}

            {_apiData.status === 'rejected' && <div className="my-0 alert alert-danger" role="alert"><i className='bi-emoji-dizzy'></i> Unable to load <strong>receiving slips</strong>.</div>}

            {_apiData.status === 'fulfilled' &&

                <div className='sticky-top pt-2'>

                    {/* SEARCH ui */}
                    <form className="mb-2 px-2 d-flex gap-2 justify-content-between align-items-center" role="search">
                        <input type="search" onChange={onSearchChange} className="form-control" id="searchTerm" defaultValue={_filterSearchTerm} placeholder="Search..." aria-label="Search" />

                        { /* show CLEAR only when searching... */
                            _receivingPending.length + _receivingReceived.length < _apiTotal &&
                            <button
                                className='d-flex flex-nowrap btn btn-success'
                                aria-label="load"
                                onClick={() => {
                                    document.getElementById("searchTerm").value = ""
                                    dispatch(filterReceiving({
                                        sort: _filterSort,
                                        searchTerm: ''
                                    }))
                                }}
                            >
                                <i className='bi-x-circle'></i>&nbsp;Clear
                            </button>
                        }
                    </form>

                    {/* SORT ui */}
                    <div className='px-2 d-flex gap-2 justify-content-between align-items-center'>
                        <button
                            className={'btn ' + (_filterSort === 'vendor' ? 'btn-dark' : 'btn-outline-dark border-2')}
                            aria-label="sort by vendor"
                            onClick={onVendorSort}
                        >
                            vendor
                        </button>
                        <button
                            className={'btn ' + (_filterSort === 'date' ? 'btn-dark' : 'btn-outline-dark border-2')}
                            aria-label="sort by date"
                            onClick={onDateSort}
                        >
                            date
                        </button>
                        <button
                            className={'btn ' + (_filterSort === 'job' ? 'btn-dark' : 'btn-outline-dark border-2')}
                            aria-label="sort by date"
                            onClick={onJobSort}
                        >
                            job
                        </button>
                        <div className='flex-grow-1 text-end'>
                            <a
                                aria-label="notice not found"
                                href="https://app.smartsheet.com/b/form/c420a8ab323a4743abfb9aec0ef9f995"
                            >
                                not found <i className='bi-box-arrow-up-right'></i>
                            </a>
                        </div>
                    </div>


                    {/* TABS ui */}
                    {_apiData.status === 'fulfilled' && <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className={"nav-link" + (_uiActiveTab === 'pending' ? " active" : "")} id="pending-tab" data-bs-toggle="tab" data-bs-target="#pending-tab-content" type="button" role="tab" aria-controls="pending" aria-selected="true"><i className='bi-clipboard'></i> pending</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className={"nav-link" + (_uiActiveTab === 'received' ? " active" : "")} id="received-tab" data-bs-toggle="tab" data-bs-target="#received-tab-content" type="button" role="tab" aria-controls="received" aria-selected="false"><i className='bi-clipboard-check'></i> received ← <input className="form-check-input" type="checkbox" checked={_uiShowOld} onChange={onShowOldChange} id="showOld" /> 45 days</button>
                        </li>
                    </ul>}

                </div>
            }





            {/* PENDING ----- */}
            <div className="tab-content" id="noticesTabContent">
                <div className={"tab-pane fade" + (_uiActiveTab === 'pending' ? " show active" : "")} id="pending-tab-content" role="tabpanel" aria-labelledby="pending-tab">
                    {_apiData.status === 'fulfilled' &&
                        _receivingPending.map((pendingItem, idx) => {

                            // Coda calculates all attachments into a singular string separating filenames with a comma
                            // !!! IF there is a comma in the attachment name or filename this will break
                            const _attachNames = cell(pendingItem, RECEIVING.acknowledgement).split(",")
                            const _attachFiles = cell(pendingItem, RECEIVING.fileUrl).split(",")

                            const _attachments = _attachFiles.map((el, idx) => {
                                const extension = _attachFiles[idx].split(".")[_attachFiles[idx].split(".").length - 1]
                                return {
                                    ext: extension,
                                    filename: _attachFiles[idx],
                                    name: _attachNames[idx],
                                    isImg: extension && extension.toLowerCase().match(/(png|jpg|jpeg|gif)/ig),
                                    isPdf: extension && extension.toLowerCase().match(/(pdf)/ig)
                                }
                            })

                            return <div className='p-2 border-bottom border-2' key={idx} id={pendingItem.id}>
                                <div className='d-flex gap-2 justify-content-between align-items-center'>
                                    <div>
                                        {cell(pendingItem, RECEIVING.recProblem) && <div className="badge bg-warning mb-2">ADDITIONAL DELIVERY</div>}

                                        <div className='fw-bold'>
                                            <span dangerouslySetInnerHTML={{ __html: _hiliteSearchText(cell(pendingItem, RECEIVING.vendor).toUpperCase()) }}></span> {cell(pendingItem, RECEIVING.timberCert).match(/(fsc|pefc)/ig) ? <span className="badge bg-danger">{cell(pendingItem, RECEIVING.timberCert)}</span> : ""}
                                        </div>

                                        <div className='text-dark'>{cell(pendingItem, RECEIVING.summary)}</div>
                                    </div>

                                    {_uiReceivedReceiving !== pendingItem.id &&
                                        <div>
                                            <Link
                                                className='btn btn-sm btn-primary text-nowrap'
                                                to={`/receiving/${pendingItem.id}`}
                                                key={pendingItem.id}
                                            >
                                                Receive <i className='bi bi-arrow-right-short'></i>
                                            </Link>
                                        </div>
                                    }
                                    {_uiReceivedReceiving === pendingItem.id && <div className='text-warning'><i className='bi-hourglass-split'></i> Receiving...</div>}
                                </div>

                                <table className="mt-2 table table-sm table-borderless">
                                    <tbody>
                                        <tr className="border-top">
                                            <td className="label-data w-25">EXPECTED</td>
                                            <td className={(compareAsc(new Date(cell(pendingItem, RECEIVING.expected)), _reallyLateShipDate) === -1 && _filterSort === "date" ? 'fw-bold text-danger'
                                                : compareAsc(new Date(cell(pendingItem, RECEIVING.expected)), _lateShipDate) === -1 && _filterSort === "date" ? 'fw-bold text-warning'
                                                    : _filterSort === "date" ? 'fw-bold text-success'
                                                        : '')}>{format(new Date(cell(pendingItem, RECEIVING.expected)), "eee, LLL d")} {cell(pendingItem, RECEIVING.destination).match(/pick/ig) && <span className="badge bg-warning">REQ PICKUP</span>}</td>
                                        </tr>
                                        <tr className="border-top">
                                            <td className="label-data">JOB</td>
                                            <td dangerouslySetInnerHTML={{ __html: _hiliteSearchText(cell(pendingItem, RECEIVING.job).replace(jobIconsRegex, "")) }}></td>
                                        </tr>

                                        {_attachments.map((item, idx) => {
                                            if (item.filename.trim() === '') return
                                            return <tr className="border-top" key={idx}>
                                                <td>&nbsp;</td>
                                                <td className='text-break'>
                                                    <a href={item.filename}>
                                                        {item.isPdf && <i className='bi-file-earmark-pdf'></i>}
                                                        {item.name}
                                                    </a>
                                                </td>
                                            </tr>
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        })}

                    {_apiData.status === 'fulfilled' && _receivingPending.length === 0 && <div><p>No pending receiving slips found.</p>{_filterSearchTerm !== '' && <p>You are currently searching for <strong>{_filterSearchTerm}</strong>.</p>}</div>}

                </div>

                {/* RECEIVED ----- */}
                <div className={"tab-pane fade" + (_uiActiveTab === 'received' ? " show active" : "")} id="received-tab-content" role="tabpanel" aria-labelledby="received-tab">
                    {_apiData.status === 'fulfilled' &&
                        _receivingReceived.map((receivedItem, idx) => {

                            // Coda calculates all attachments into a singular string separating filenames with a comma
                            // !!! IF there is a comma in the attachment name or filename this will break
                            const _attachNames = cell(receivedItem, RECEIVING.acknowledgement).split(",")
                            const _attachFiles = cell(receivedItem, RECEIVING.fileUrl).split(",")

                            const _attachments = _attachFiles.map((el, idx) => {
                                const extension = _attachFiles[idx].split(".")[_attachFiles[idx].split(".").length - 1]
                                return {
                                    ext: extension,
                                    filename: _attachFiles[idx],
                                    name: _attachNames[idx],
                                    isImg: extension && extension.toLowerCase().match(/(png|jpg|jpeg|gif)/ig),
                                    isPdf: extension && extension.toLowerCase().match(/(pdf)/ig)
                                }
                            })

                            const isOld = compareDesc(sub(new Date(), { days: 45 }), new Date(cell(receivedItem, RECEIVING.recDate))) === 1 ? false : true

                            return ((isOld && _uiShowOld) || !isOld) && <div className={'p-2 border-bottom border-2' + (_uiActiveReceiving && _uiActiveReceiving === receivedItem.id ? ' bg-light' : '')} key={idx} id={receivedItem.id}>
                                <div className='d-flex gap-2 justify-content-between align-items-center'>
                                    {isOld && <div className='text-center pe-2 label-data border-end border-1' style={{ width: "40px" }}>
                                        {format(new Date(cell(receivedItem, RECEIVING.recDate)), "LLL")}<br />{format(new Date(cell(receivedItem, RECEIVING.recDate)), "dd")}
                                    </div>}
                                    <div className='flex-grow-1'>
                                        {cell(receivedItem, RECEIVING.recProblem) && <div className="badge bg-warning mb-2">MULTIPLE DELIVERIES</div>}

                                        <div className={(isOld ? 'text-muted ' : '') + 'fw-bold'} >
                                            <span dangerouslySetInnerHTML={{ __html: _hiliteSearchText(cell(receivedItem, RECEIVING.vendor).toUpperCase()) }}></span> {cell(receivedItem, RECEIVING.timberCert).match(/(fsc|pefc)/ig) ? <span className="badge bg-danger">{cell(receivedItem, RECEIVING.timberCert)}</span> : ""}
                                        </div>

                                        <div className={isOld ? 'text-muted ' : 'text-dark'} >{cell(receivedItem, RECEIVING.summary)}</div>
                                    </div>
                                    <div>
                                        <button
                                            className={'btn btn-sm ' + (isOld ? 'btn-outline-primary' : 'btn-primary')}
                                            onClick={evt => {
                                                onView({
                                                    evt: evt,
                                                    receivingId: receivedItem.id,
                                                    showOld: _uiShowOld
                                                })
                                            }}
                                            key={receivedItem.id}
                                        >
                                            View
                                        </button>
                                    </div>
                                </div>


                                {!isOld && <table className="mt-2 table table-sm table-borderless">
                                    <tbody>
                                        <tr className="border-top">
                                            <td className="label-data w-25">RECEIVED</td>
                                            <td className='fw-bold'>{format(new Date(cell(receivedItem, RECEIVING.recDate)), "eee, LLL d")} <i className='bi-check'></i></td>
                                        </tr>
                                        <tr className="border-top">
                                            <td className="label-data">JOB</td>
                                            <td dangerouslySetInnerHTML={{ __html: _hiliteSearchText(cell(receivedItem, RECEIVING.job).replace(jobIconsRegex, "")) }}></td>
                                        </tr>
                                        {cell(receivedItem, RECEIVING.recNotes) && <tr className="border-top">
                                            <td className="label-data">REC NOTES</td>
                                            <td className='fst-italic'>{cell(receivedItem, RECEIVING.recNotes)}</td>
                                        </tr>}
                                        {_attachments.map((item, idx) => {
                                            return <tr className="border-top" key={idx}>
                                                <td>&nbsp;</td>
                                                <td className='text-break'>
                                                    <a href={item.filename}>
                                                        {item.isPdf && <i className='bi-file-earmark-pdf'></i>}
                                                        {item.name}
                                                    </a>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>}
                            </div>
                        })}

                    {_apiData.status === 'fulfilled' && _receivingReceived.length === 0 && <div><p>No received receiving slips found.</p>{_filterSearchTerm !== '' && <p>You are currently searching for <strong>{_filterSearchTerm}</strong>.</p>}</div>}

                </div>

                {_apiData.status === 'fulfilled' && <div className='d-flex gap-2 p-2'>

                    <div className='text-center'>Showing<br />{_receivingPending.length + _receivingReceived.length} of {_apiTotal}</div>
                    <button
                        className='btn btn-outline-dark border-2'
                        aria-label="load"
                        onClick={() => dispatch(fetch())}
                    >
                        <i className='bi-arrow-clockwise'></i>
                    </button>

                </div>}
            </div>

        </div>
    )
}